import React from 'react';
import TrackerHeader from './TrackerHeader';
import WorldMap from './WorldMap';
import CountrySelector from './CountrySelector';
import CountryList from './CountryList';
import NewLineChart from './NewLineChart';

const HomeView = (props) => {
    function countriesListFunction(countryList){
        var changedToCountry = props.history.location.changeToCountry;
        if(changedToCountry !== undefined && changedToCountry !== '' && changedToCountry !== null){
            var selectedCountry = countryList.filter(function(country){
                return country.official_name === changedToCountry;
            })
            
            handleCountryClick(selectedCountry[0]); 
        }
    }
    // let country = props.countries;
    // let SortList = country.sort();
    function handleCountryChange(event) {
        const country = event.target.value;
        document.querySelector(".App").classList.add("loader");
        setTimeout(() => {
            changePath("/country-detail/" + country, country )
            document.querySelector(".App").classList.remove("loader");
        }, 800);
        document.querySelector(".App").classList.add("loader-leave");
    }

    function handleCountryClick(country) {
        document.querySelector(".App").classList.add("loader");
        setTimeout(() => {
            document.querySelector(".App").classList.remove("loader");
            changePath("/country-detail/" + country.official_name, country.official_name )
        }, 800);
        document.querySelector(".App").classList.add("loader-leave");
    }

    function handleHowClick() {
        document.querySelector(".App").classList.add("loader");
        setTimeout(() => {
            document.querySelector(".App").classList.remove("loader");
            changePath('/how-it-works')
        }, 800);
        document.querySelector(".App").classList.add("loader-leave");
       
    }
    
    function changePath(_path, countryname) {
        const { history } = props;
        const path = {
            pathname: _path,
            selectedCountry: countryname
        };
        history.push(path);
    }
    return (
        <>
            <TrackerHeader onCTAClick={handleHowClick}/>
            <WorldMap  handleToggle={props.handleToggle}
                view={props.view} isCases={props.isCases} onCountryClick={handleCountryClick} progressData={props.progressData} projectionData ={props.projectionData} colors={props.colors} percentages={props.percentages} 
                />
            <CountrySelector countries={props.countries} onCountryChange={handleCountryChange} />
            <div className="container-fluid map-homeview">
              <div className="container">
                  {!props.isCases ? <h3 className="heading-size">PROGRESS ACROSS THE COMMONWEALTH (CASES)</h3> : <h3 className="heading-size">PROGRESS ACROSS THE COMMONWEALTH (DEATHS)</h3>}
              
              <NewLineChart  isCases={props.isCases} isPopup={props.isPopup} append={props.view} isYaxisLabel={true} country={'Commonwealth'}  csvData={props.progressData} csvDataProjection={props.projectionData} /> </div>
            </div>
            <CountryList handleToggle={props.handleToggle} onCountriesList = {countriesListFunction}
                view={props.view} isCases={props.isCases} countries={props.percentages} onCountryClick={handleCountryClick} progressData={props.progressData} projectionData={props.projectionData}/>
            
        </>
    );
  }

export default HomeView;
