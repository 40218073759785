// export default function HTMLLoader({ country }) {}
import React from "react";

export default class HTMLLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      html: ""
    };

    this.formatCountry = this.formatCountry.bind(this);
  }

  formatCountry = country => (
    country.replace(/\s/g, '-')
  )

  componentDidMount() {
    const { folder, country } = this.props;
    fetch(`${process.env.PUBLIC_URL}/html/${folder}/${this.formatCountry(country)}.html`)
      .then(response => response.text())
      .then(html => {
        this.setState({
          html
        });
      });
  }

  render() {
    const { html } = this.state;
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    );
  }
}
