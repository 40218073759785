import React from 'react'
import '../style/countrybarchart.scss'
import NewLineChart from './NewLineChart'
const CountryBarChart = (props) => {
    
    let {onCountryClick, iso2, official_name, who_region, ...chartProps} = props;
    let flagClass = 'flagbox flag-icon-' +  (iso2? iso2.toString().toLowerCase() : '')
    return (
        <div className="home-bar-block col-md-4">
            <div className="bar-chart">
                <div className="country-flag">
                <div className={flagClass}></div>
                <div className="country-name country-name-home" onClick={ () => onCountryClick(official_name) }>{official_name}</div>
                </div>
                <NewLineChart selectedOption={props.selectedOption} isGrid={true} isPopup={false} isCases={props.isCases}  append={props.view} country = {official_name}  csvData={props.progressData} csvDataProjection={props.projectionData} />
                <div className="more-about-country" onClick={ () => onCountryClick(official_name) }>MORE ABOUT { official_name.toString().toUpperCase() }<span className="country-arrow"></span></div>
            </div>
        </div>
    )
}

export default CountryBarChart;
