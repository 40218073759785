import React from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom";

// import "bootstrap/dist/css/bootstrap.css";
// import './orgsite.css'
import './app.scss'
import 'bootstrap/scss/bootstrap.scss'

import './fonts/fonts.css'


import HomeView from './newcomps/HomeView';
import fetchAppData from './services/service';
import CWTrackerPartners from './newcomps/CWTrackerPartners';
import HowTrackerWorks from './newcomps/HowTrackerWorks';
import CountryDetailsPage from './components/CountryDetailsPage';

const NoMatchPage = () => {
  return (
    <h3>404 - Sorry! Not found</h3>
  );
};

// const DetailsPage = () => {
//     return (
//       <h3>Details Page, coming soon ..... </h3>
//     );
//   };

const Loader = () => {
  return (
    <div className="loader text-center">Loading....</div>
  )
}

class MalariaTracker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        isCases: false,
        view: 'cases'
    }
    this.changeView = this.changeView.bind(this)
  }
  handleToggle=() => {
    this.setState(prevState => ({
        isCases: !prevState.isCases
    }),()=> {
        const mapState = !this.state.isCases ? "cases" : "deaths"
        this.changeView(mapState)
    });
}

changeView(view){
    this.setState({view: view})
}

  componentDidMount () {
    fetchAppData().then((result)=> {
        console.log('setting data')
        this.setState({
            ...this.state,
            ...result,
            loading: false,
        }, function(){
          //console.log('state after data call: ', this.state)
      })
    })

    //
}

componentDidUpdate() {
  console.log('App :: didUpdate')
}

  render() {
    console.log('TOP');
    console.log(this.state.percentages)
    return (

      <div className="App">
      {this.state.loading? <Loader/>:
        <BrowserRouter basename="/commonwealth-tracker">
          <Switch>
            <Route exact={ true } path="/"
              render={(props) => <HomeView {...props}
                colors={this.state.colors}
                countries={this.state.CWcountries}
                percentages={this.state.percentages}
                mapdata={this.state.mapdata}
                cwAverageData={this.state.cwAverageData}
                progressData ={this.state.progressChartData}
                projectionData = {this.state.projectionChartData}
                handleToggle={this.handleToggle}
                view={this.state.view}
                isCases={this.state.isCases}
                />} >
            </Route>
            <Route exact={ true } path="/country-detail/:selectedCountry"
              render={(props) => <CountryDetailsPage {...props}
                handleToggle={this.handleToggle}
                view={this.state.view}
                isCases={this.state.isCases}
                />} >
            </Route>
            <Route path="/how-it-works" component={HowTrackerWorks} />
            <Route component={NoMatchPage} />
          </Switch>
        </BrowserRouter>}
        <CWTrackerPartners/>
      </div>
    );
  }

}

export default MalariaTracker;
