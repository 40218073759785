import React, { useState } from "react";
import "../style/map-key.scss";
import "../style/map-key-legend.scss";

const MapKey = (props) => {

    const [collapsed, toggleCollapse] = useState(true);

    return (
        <div className="col-md-12 map-legend">
            <div className="map-key">
                <div className="map-key-header">
                    <div className="toggle" onClick={ ()=>toggleCollapse(!collapsed) }>{collapsed ? 'OPEN MAP KEY' : 'OPEN MAP KEY'}<span className={collapsed ? 'dwn-arrows' : 'up-arrows'}></span></div>
                </div>
                <div className={collapsed ? 'map-key-body hidden':'map-key-body'}>
                    <h4>THE COMMONWEALTH:</h4>
                    <ul className="key-list">
                        <li><span className="key no-malaria"></span>NO MALARIA</li>
                        <li><span className="key on-track"></span>ON TRACK</li>
                        <li><span className="key near-track"></span>NEAR TRACK</li>
                        <li><span className="key off-track"></span>OFF TRACK</li>
                    </ul>
                    <h4>REST OF WORLD:</h4>
                    <ul className="key-list">
                        <li><span className="key wo-malaria"></span>WITHOUT MALARIA</li>
                        <li><span className="key w-malaria"></span>WITH MALARIA</li>
                    </ul>
                </div>
                
                {!props.isCases? 
                    <div className="map-toggle-key-mbl cases">SHOWING CASES</div>:
                    <div className="map-toggle-key-mbl deaths">SHOWING DEATHS</div>}
            </div>
        </div>
    );
}

export default MapKey;



