import React from 'react'

import '../style/cw-tracker-partners.scss'

const CWTrackerPartners = () => {
    return (
        <div className="container-fluid partners">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3 className="heading">COMMONWEALTH TRACKER PARTNERS</h3>
                    </div>
                </div>
                <div className="row logos">
                    <a href="https://alma2030.org/" className="logo col" target="_blank" rel="noopener noreferrer"><img src="/images/alma.png" className="logo-img" alt="alma"/></a>
                    <a href="https://www.aplma.org/" className="logo col" target="_blank" rel="noopener noreferrer"><img src="/images/aplma.png" className="logo-img" alt="aplma" /></a>
                    <a href="https://apmen.org/" className="logo col" target="_blank" rel="noopener noreferrer"><img src="/images/APMEN.jpg"  className="logo-img"  alt="apmen" /></a>
                    <a href="https://thecommonwealth.org/" className="logo footer-logo col" target="_blank" rel="noopener noreferrer"><img src="/images/CW_HORIZONTAL_BLUE.jpg" className="logo-img" alt="commonwealth" /></a>
                    <a href="https://malarianomore.org.uk/" className="logo col" target="_blank" rel="noopener noreferrer"><img src="/images/mnm.png" className="logo-img" alt="mnm" /></a>
                    <a href="https://endmalaria.org/" className="logo col" target="_blank" rel="noopener noreferrer"><img src="/images/rbm.png" className="logo-img" alt="rbm" /></a>
                    <a href="https://www.who.int/" className="logo col" target="_blank" rel="noopener noreferrer"><img src="/images/who.png" className="logo-img" alt="who" /></a>
                </div>
                <div className="row designed-by">
                    <div className="col-md-12 text-center"><a href="https://www.isobar.com/" target="_blank" rel="noopener noreferrer"><span>Designed and built by </span><img src="/images/isobar.svg" alt="isobar"></img></a></div>
                </div>
            </div>
        </div>
    )
}

export default CWTrackerPartners;

