import React from 'react';

import '../style/stickyBar.scss';
import SwitchToggle from'./SwitchToggle';

const StickyBar = (props) => {
    return(
        <div className="row mbl-view">
        <div className="footer">
            <ul className="legend">
                {!props.isCases ?
                <li><span className="mark incidence-rate"></span>INCIDENCE RATE PER 1,000 AT RISK</li>:
                <li><span className="mark mortality-rate"></span>MORTALITY RATE PER 100,000 AT RISK</li>}
                {!props.isCases? 
                <li><span className="mark target-incidence"></span><span className="mark target-incidence"></span>TARGET INCIDENCE RATE PER 1,000 AT RISK</li>:
                <li><span className="mark target-incidence"></span><span className="mark target-incidence"></span>TARGET MORTALITY RATE PER 100,000 AT RISK</li>}
            </ul>
            <SwitchToggle isOn={props && props.isCases} onColor="#FF5AE4" handleToggle={props && props.handleToggle} />
        </div>
        </div>
    )
}

export default StickyBar;