import React, {useState} from 'react'
import CountryBarChart from './CountryBarChart';
import RegionFilter from './RegionFilter';
import { REGION_LIST } from '../constants';

import '../style/country-list.scss';

const CountryList = (props) => {
  const [selectedRegion, setSelectedRegion] = useState(REGION_LIST.SHOW_ALL);
        let countries = filterByRegion(props.countries, selectedRegion)
        let checkForCommonwealth = countries.findIndex(a => a.official_name === "Commonwealth")
        if(checkForCommonwealth >= 0) {
            countries.splice(countries.findIndex(a => a.official_name === "Commonwealth") , 1)
  }
  console.log(countries)
        let sortList = countries?.sort(function(a, b) {
            var textA = a.official_name?.toUpperCase();
            var textB = b.official_name?.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        props.onCountriesList(sortList);
        return(
            <div className="container">
                <div className="row">
                    <ContryByCountryHeader/>
                    <RegionFilter selectedOption={selectedRegion} onChange={(e)=>setSelectedRegion(e.target.value)}/>
                </div>
                <div className="row home-footer">
                    { sortList.map((country, i) =>  {
                         return <CountryBarChart handleToggle={props.handleToggle}  selectedOption={selectedRegion}
                         view={props.view} isCases={props.isCases} key={country.iso2} {...country} progressData={props.progressData} projectionData ={ props.projectionData} onCountryClick={()=>props.onCountryClick(country)}/>
                    })
                    }
                </div>
            </div>
        )
}

const ContryByCountryHeader = () => {
    return(
        <div className="col-md-12 cbyc-progress">
            <h2 className="heading-2 text-center">Country by Country Progress across the commonwealth</h2>
        </div>
    )
}

/**
 *
 * @param {*} percentages - Object containing all countries percentage data
 * @param {*} region - Selected region from the list of regions, default is SHOW_ALL
 * @return {} Returns an object after filtering with region
 */
function filterByRegion(percentages, region = REGION_LIST.SHOW_ALL) {
    if(region !== REGION_LIST.SHOW_ALL) {
        return percentages.filter((item)=> item.who_region === region)
    }
    return percentages;

}

export default CountryList;
