import axios from 'axios';
import { COUNTRIES_COLOR_URL, COUNTRIES_PROGRESS_URL, COUNTRIES_PROJECTION_URL, CURRENT_YEAR } from '../constants';
import { csvToJson, normalizeTrackerData, normalizeToPercentage, getCWCountries, getCWAverage } from '../utils/utils';

/**
 * fetchAppData
 * Service function used to fetch data from csv files
 * getColors, getTrackerProgress, getTrackerProjection
 * returns data in json format
 */

const fetchAppData = () => {
    console.log('fetching... app-data')

    function getColors() {
        return axios.get(COUNTRIES_COLOR_URL);
    }

    function getTrackerProgress() {
        return axios.get(COUNTRIES_PROGRESS_URL);
    }

    function getTrackerProjection() {
        return axios.get(COUNTRIES_PROJECTION_URL);
    }

    return axios.all([getColors(), getTrackerProgress(), getTrackerProjection()])
    .then(axios.spread(function (colorsData, progressData, projectionData) {
        // All requests are now complete
        let colors = csvToJson(colorsData.data);
        let progress = normalizeTrackerData(csvToJson(progressData.data));
        let projection = normalizeTrackerData(csvToJson(projectionData.data));

        /**
         * TODO
         */
        //derive the year from progress data - latest year data available
        //right now hard-coded to 2017
      //const currYear = '2017'
      console.log(csvToJson(progressData.data)) // when converted to normalizeToPercentage, gets an additional undefined
      let percentages = normalizeToPercentage(progress, projection, CURRENT_YEAR);
        let cwAverageData = getCWAverage(percentages, CURRENT_YEAR)
        let CWcountries = getCWCountries(percentages);
        const progressChartData = csvToJson(progressData.data);
        const projectionChartData = csvToJson(projectionData.data);

        return {
            colors,
            percentages,
            CWcountries,
            cwAverageData,
            progressChartData,
            projectionChartData
        }
    }));
}

export default fetchAppData;
