import React from 'react'
import EXsvgBorderLeft from '../components/EXsvgBorderLeft';
import '../style/how-tracker-works.scss'
const HowTrackerWorks = (props) => {

    function changePath() {

        document.querySelector(".App").classList.add("loader");
        setTimeout(() => {
            document.querySelector(".App").classList.remove("loader");
            props.history.push({pathname: '/'});
        }, 800);
        document.querySelector(".App").classList.add("loader-leave");
    }

    return (
        <div className="container how-works">
            <div className="row">
                <div className="col-md-2">
                    <div className="btn-back" onClick={changePath}><span></span>Back to tracker</div>
                </div>
                <div className="col-md-8 text-center">
                    <h2 className="heading-2 h-underline">HOW DOES THE TRACKER WORKS</h2>
                </div>
            </div>
            <div className="row">
                <div className="desc col-md-8 offset-md-2">
                    <h3>The Commonwealth’s commitment to halve malaria by 2023</h3>
                    <p>At the Commonwealth Heads of Government meeting 2018, leaders <a href="https://www.chogm2018.org.uk/sites/default/files/CHOGM%202018%20Communique.pdf" target="_blank" rel="noopener noreferrer">committed</a> to <span className="bold-text">“halve malaria across the Commonwealth by 2023”.</span>  This commitment is referred to hereafter as the “Commonwealth commitment”.
                    </p>
                    <p>Heads of government agreed that progress on these commitments should be considered every two years at the Commonwealth Health Ministers’ Meeting and progress should be reported at CHOGM.
                    </p>
                    <p>The Commonwealth Secretariat, at the request of the Commonwealth Advisory Committee on Health (CACH), have invited technical partners on malaria to provide regular briefings on progress to the Commonwealth commitment. A group of technical partners working on malaria comprised of the African Leaders Malaria Alliance, the Asia Pacific Leaders Malaria Alliance, Malaria No More UK, the RBM partnership to end malaria and the World Health Organisation have subsequently produced periodic reporting.
                    </p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>The Commonwealth malaria tracker </h3>
                    <p>In advance of the CHMM 2021 and CHOGM 2021, the Commonwealth Malaria tracker has been developed with the objective of using a single platform to visualise progress towards the Commonwealth commitment, and to assist member states in their process of review.</p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>How the Commonwealth commitment is measured </h3>
                    <p>Following the agreement of the Commonwealth commitment at CHOGM 2018, malaria technical experts proposed two indicators to measure the required progress. These were discussed and agreed with members of the Commonwealth Advisory Committee on Health (CACH) at its December 2019 meeting.
                    </p>
                    <p>To ensure that the Commonwealth commitment did not create additional or inconsistent targets for member states, it was proposed to align the targets with the WHO’s Global Technical Strategy (GTS).

                    </p>
                    <p>The GTS underpins the Sustainable Development Goals target related to malaria, already adopted by member states and using 2015 as the baseline year for measuring progress. On this basis, the CACH instructed the Commonwealth Secretariat to work with technical experts, including the WHO, to produce reporting based on these indicators.</p>
                    <p>The two indicators to measure progress towards the commitment apply both to the Commonwealth as an aggregate and to any Commonwealth country with cases or deaths from malaria in 2015. </p>
                    <p>These are:</p>
                    <ol>
                        <li>On track to reduce malaria incidence by 50% by 2023</li>
                        <li>On track to reduce malaria mortality rates by 50% by 2023</li>
                    </ol>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>How progress is reported </h3>
                    <p>Countries are “on-track”, “ or “off-track” to achieve the Commonwealth commitment. Countries are shown to be on track where they have:</p>
                    <ul>
                        <li>Reduced the malaria case incidence rate (per 1,000 of population at risk) to a level equal to, or greater than, the linear projection required to reach halving by 2023 (against a 2015 baseline).</li>
                        <li>Reduced the malaria mortality rate (per 100,000 of population at risk) to a level equal to, or greater than, the linear projection required to reach halving by 2023 (against a 2015 baseline).</li>
                    </ul>
                    <p>On each country graph, the extent of progress for the latest year of 2019 is further stratified. A label is shown on each country graph which identifies a country as being in one of three states:</p>
                    <ul className="list-style-none">
                        <li><span className="bold-text">Green</span>: Progress is equal to, or greater than, the projected level required to be on track in 2019.</li>
                        <li><span className="bold-text">Red</span>: The country has achieved less than 99% of the progress required to be on track in 2023. </li>
                    </ul>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>About the data: </h3>
                    <p>All country data on this site is taken directly from the <a href="https://www.who.int/malaria/publications/world_malaria_report/en/" target="_blank" rel="noopener noreferrer">2020 World Malaria Report</a> .  Case incidence, mortality rate and country progress status are derived directly from this data set. </p>

                    <p>The WHO’s estimates are consistent across all countries in the Commonwealth and enable member states to review progress towards the halving commitment without requiring any additional data reporting by individual countries. Given the significant range of countries within the Commonwealth, the use of a standardised reporting method for measuring progress was vital to delivering this report. </p>
                    <p>Data for the Commonwealth is produced by aggregating the total for all relevant member countries in each individual year. Relevant member countries are defined as those which had any malaria cases or deaths in the WHO’s data for 2015. </p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>About the partners</h3>
                    <p>Content for this site was developed by a group of malaria technical partners including: </p>
                    <ul className="list-style-dash">
                        <li>The African Leaders Malaria Alliance</li>
                        <li>The Asia Pacific Leaders Malaria Alliance</li>
                        <li>Malaria No More UK,</li>
                        <li>The RBM Partnership To End Malaria</li>
                        <li>The World Health Organisation </li>
                    </ul>
                    <p>Development and design for the site have been provided by Isobar.</p>
                </div>
                <div className="desc col-md-8 offset-md-2">
                    <h3>Find out more:</h3>
                    <p className="padding-last">If you have any questions about the site, please contact <a href="mailto:infomnm@malarianomore.org.uk"> infomnm@malarianomore.org.uk </a> </p>
                </div>
            </div>
        </div>
    )
}

export default HowTrackerWorks;