import {
  line,
  scaleTime,
  axisLeft,
  scaleLinear,
  format,
  extent,
  axisBottom,
  curveCatmullRom,
  nest,
  min,
  max,
  symbol,
  selectAll,
  symbolTriangle
} from 'd3';

function lineChart(svg, { margin, height, width }, props, appendstatus) {
  const { append, csvData, csvDataProjection, country } = props;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  let years = [];
  const countrySelector = country.replaceAll(' ','-');
  const filteredData = csvData.filter((item) => {
    return item.official_name === country;
  });

  const lineyear = filteredData[filteredData.length - 1].year;

  const year = lineyear < '2018' ? '2018' : lineyear;

  const yearData = filteredData.filter((item) => item.year === year)?.[0];

  const yScaleData =
    append === 'deaths' ? yearData.mortality_rate : yearData.case_incidence2;

  const status =
    append === 'deaths' ? yearData.mortality_status : yearData.cases_status;

  const projectionData = csvDataProjection.filter((item) => {
    years.indexOf(item.year) < 0 && years.push(item.year);
    return item.year > 2014 && item.official_name === country;
  });

  const progressData = csvData.filter((item) => {
    years.indexOf(item.year) < 0 && years.push(item.year);
    return item.year > 2014 && item.official_name === country;
  });

  const g = svg.selectAll('.container').data([null]);

  // Y Axis Left
  const gEnter = g.enter().append('g').attr('class', 'container');
  gEnter.merge(g).attr('transform', props.isPopup ?  `translate(${margin.left-25},${margin.top-2})` : !props.isDetailpage ? `translate(${margin.left-5},${margin.top-5})` : `translate(${margin.left},${margin.top-5})` );

  let combined = [0]
    .concat(progressData.concat(projectionData))
    .filter((item) => item !== 0);

  const yScaleMortality = scaleLinear()
    .domain([
      0,
      max(combined, (d) => {
        let v1;
        let v2;
        d.mortality_rate ? (v1 = d.mortality_rate) : (v1 = 0);
        d.expected_mortality_rate ? (v2 = d.expected_mortality_rate) : (v2 = 0);
        return Math.max(v1, v2);
      }),
    ])
    .range([innerHeight, 0])
    .nice();

  const yScaleCases = scaleLinear()
    .domain([
      0,
      max(combined, (d) => {
        let v1;
        let v2;
        d.case_incidence2 ? (v1 = d.case_incidence2) : (v1 = 0);
        d.expected_case_incidence ? (v2 = d.expected_case_incidence) : (v2 = 0);
        return Math.max(v1, v2);
      }),
    ])
    .range([innerHeight, 0])
    .nice();

  const scaleType = append === 'deaths' ? yScaleMortality : yScaleCases;

  const yAxis = axisLeft(scaleType)
    .tickSize(-innerWidth)
    .tickFormat((d) => d)
    .tickPadding(10);

  const yAxisGEnter = gEnter.append('g').attr('class', 'y-axis');
  const yAxisG = g.select('.y-axis');
  yAxisGEnter.merge(yAxisG).call(yAxis).selectAll('.domain').remove();

  yAxisGEnter
    .append('text')
    .attr('class', 'axis-label')
    .attr('y', props.isPopup? -38 : !props.isDetailpage? -48  :-62)
    .attr('fill', 'black')
    .attr('transform', `rotate(-90)`)
    .attr('text-anchor', 'middle')
    .merge(yAxisG.select('.axis-label'))
    .attr('x', -innerHeight / 2)
    .text(!props.isCases? 'Incidence rate per 1,000 at risk': 'Mortality rate per 100,000 at risk');

  // X Axis
  const xScale = scaleTime()
    .domain(
      extent([min(years, (x) => +x + 5), max(years, (x) => +x)], (d) => d)
    )
    .range([10, innerWidth - 10]);

  const xAxis = axisBottom(xScale)
    .ticks(years.length / 2)
    .tickFormat(format(''));

  const xAxisGEnter = gEnter.append('g').attr('class', 'x-axis');
  const xAxisG = g.select('.x-axis');
  xAxisGEnter
    .merge(xAxisG)
    .call(xAxis)
    .attr('transform', `translate(0,${innerHeight})`)
    .select('.domain')
    .remove();

  // Middle line
  gEnter
    .append('line')
    .attr('class', 'selected-year-line')
    .attr('y1', -10)
    .merge(g.select('.selected-year-line'))
    .attr('x1', xScale(year))
    .attr('x2', xScale(year))
    .attr('y2', innerHeight + 10);

    const xValue = (d) => d.year;

  const yValue = (d, type) => {
    return d[type];
  };

  const lineGenerator = (type) =>
    line()
      .x((d) => {
        return xScale(xValue(d));
      })
      .y((d) => {
        if (type === 'expected_mortality_rate') {
          return yScaleMortality(yValue(d, type));
        } else if (type === 'mortality_rate') {
          return yScaleMortality(yValue(d, type));
        } else if (type === 'expected_case_incidence') {
          return yScaleCases(yValue(d, type));
        } else if (type === 'case_incidence2') {
          return yScaleCases(yValue(d, type));
        }
      })
      .curve(curveCatmullRom.alpha(0.5));

  const mortalityArr = [
    [projectionData, 'projection', 'expected_mortality_rate'],
    [progressData, 'deaths', 'mortality_rate'],
  ];

  const casesArr = [
    [projectionData, 'projection', 'expected_case_incidence'],
    [progressData, 'cases', 'case_incidence2'],
  ];

  const arr = append === 'deaths' ? mortalityArr : casesArr;

  let removeClass, removeProjection;
  removeProjection = `.line-chart svg.${countrySelector} .line-path-projection`
  if(append === "cases" ) {
    removeClass = `.line-chart svg.${countrySelector} .line-path-deaths`
    arr.map(([data, classname, type]) => {
      const linePaths = g
      .selectAll(removeProjection).remove()
      linePaths
      .enter()
      .append('path')
      .attr('class', 'line-path-' + classname)
      .merge(linePaths)
      .attr('d', (d) => {
        return lineGenerator(type)(d.values);
      });
    });
  }
  else {
    removeClass = `.line-chart svg.${countrySelector} .line-path-cases`
    arr.map(([data, classname, type]) => {
      const linePaths = g
      .selectAll(removeProjection).remove()
      linePaths
      .enter()
      .append('path')
      .attr('class', 'line-path-' + classname)
      .merge(linePaths)
      .attr('d', (d) => {
        return lineGenerator(type)(d.values);
      });
    });
  }

  arr.map(([data, classname, type]) => {
    const linePaths = g
      .merge(gEnter)
      .selectAll(removeClass).remove()
      .select(removeProjection).remove()
      .data(nested(data, props));
    linePaths
      .enter()
      .append('path')
      .attr('class', 'line-path-' + classname)
      .merge(linePaths)
      .attr('d', (d) => {
        return lineGenerator(type)(d.values);
      });
  });


  const tooltip = g.merge(gEnter).append('g').attr('class', 'tooltip-line-chart').style("opacity", 1);

  setPosition(year, yScaleData);

  function setPosition(y, data) {
    const ydata = scaleType(data);
    selectAll(`.line-chart svg.${countrySelector} g.tooltip-line-chart`).attr(
      'transform',
     props.isDetailpage && window.innerWidth > 768 ? `translate(${xScale(y)},${ydata})`: `translate(${xScale(y)+2},${ydata+7})`
    );
  }

  const color = props.isGrid ? 'black' : 'white';

  const sym =  symbol().type(symbolTriangle).size(100);
  tooltip
    .append("path")
    .attr("d", sym)
    .attr("fill", color)
    .attr("transform", props.isDetailpage ? "translate(7, -1) rotate(-90)" : "translate(7, -8) rotate(-90)");

  tooltip
    .append('rect')
    .attr('class', 'tooltip-line')
    .attr('width', props.isDetailpage && window.innerWidth > 768  ? 158 : 108)
    .attr('height', props.isDetailpage && window.innerWidth > 768 ? 44 : 28)
    .attr('x', 5)
    .attr('y', -22)
    .attr('rx', 4)
    .attr('ry', 0)
    .style('fill', color)

  tooltip
    .append('circle')
    .attr('r', props.isDetailpage && window.innerWidth > 768 ? 7 : 6)
    .attr('class', 'status')
    .attr('transform', props.isDetailpage && window.innerWidth > 768 ? `translate(${27},${0})` : `translate(${21},${-8})`);

  const textcolor = props.isGrid ? 'white' : 'black';

  tooltip
    .append('text')
    .attr('class', 'one')
    .attr('x',  props.isDetailpage && window.innerWidth > 768 ? 38 : 32)
    .attr('y', props.isDetailpage && window.innerWidth > 768 ? 8 : -3)
    .style('fill', textcolor)
    .style('font-size', props.isDetailpage && window.innerWidth > 768 ? 19 : 12);

  setchartStatus(status);

  function setchartStatus(status) {
    if (status === '>100%') {
      selectAll(`.line-chart svg.${countrySelector} circle`).attr('class', 'ontrack');
      selectAll(`.line-chart svg.${countrySelector} text.one`).text('ON TRACK');
    } else {
      selectAll(`.line-chart svg.${countrySelector} circle`).attr('class', 'offtrack');
      selectAll(`.line-chart svg.${countrySelector} text.one`).text('OFF TRACK');
    }
  }
}

const nested = (data, props) => {
  const { country } = props;
  const countryName = (d) => d.official_name;

  return nest()
    .key(countryName)
    .entries(data.filter((d) => d.official_name === country));
};

export default lineChart;
