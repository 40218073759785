import {pick} from 'lodash';
import { START_YEAR, END_YEAR, LEGEND_COLORS} from '../constants';

//Convert csv to array..
function csvToArray(strData, strDelimiter) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");
    // Create a regular expression to parse the CSV values.
    let objPattern = new RegExp((
    // Delimiters.
    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
    // Quoted fields.
    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
    // Standard fields.
    "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
    // Create an array to hold our data. Give the array
    // a default empty first row.
    let arrData = [[]];
    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;
    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    let strMatchedValue;
    while (arrMatches = objPattern.exec(strData)) {
        // Get the delimiter that was found.
        let strMatchedDelimiter = arrMatches[1];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (strMatchedDelimiter.length && (strMatchedDelimiter !== strDelimiter)) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
        }
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(
            new RegExp("\"\"", "g"), "\"");
        } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
    }
    // Return the parsed data.
    return (arrData);
}

//Converts csv to json..
function csvToJson(csv) {
    let array = csvToArray(csv);
    let objArray = [];
    for (let i = 1; i < array.length; i++) {
        objArray[i - 1] = {};
        for (let k = 0; k < array[0].length && k < array[i].length; k++) {
            let key = array[0][k];
            objArray[i - 1][key] = array[i][k]
        }
    }
    let json = JSON.stringify(objArray);
    let str = json.replace(/},/g, "},\r\n");
    return JSON.parse(str);
}

function normalizeTrackerData( data) {

    let property = 'official_name';
    let normalizedData = data.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
            acc[key] = {};
        }

        let picked = pick(obj, ['popatrisk_total', 'cases', 'deaths', 'gts_cases', 'gts_deaths', 'cases_status', 'mortality_status' ]);
        acc[key]['official_name'] = obj.official_name;
        acc[key]['iso2'] = obj.iso2;
        acc[key]['who_region'] = obj.who_region;
        acc[key]['official_name'] = obj.official_name;
        acc[key]['cases_status'] = obj.cases_status;
        acc[key]['mortality_status'] = obj.mortality_status;
        acc[key][obj.year] = picked;
        return acc;
    }, {});

    return normalizedData;
}

// function calculateCases(prog, proj) {
//     let percent = (prog/(proj*2)) * 100;
//     return Math.round(percent)
// }

// function calculateDeaths(prog, proj) {
//     let percent
//     if(proj != 0) {
//         percent = (prog/(proj*2)) * 100;
//     } else {
//         percent = proj * 100;
//     }
//     return Math.round(percent)
// }

function evalColor(percent) {
    if(percent === '>100%' ) {
        return LEGEND_COLORS.ON_TRACK.color
    } else {
        return LEGEND_COLORS.OFF_TRACK.color
    }
}

function normalizeToPercentage(progressJson, projectionJson, year) {
    let percentages = [];
    for(let key in progressJson) {
        if(key !== 'undefined') {
          let percent = {}
            const progressCases = Number(progressJson?.[key]?.[year]?.cases);
          percent.cases = (((projectionJson[key]['2015'].expected_case_incidence - projectionJson[key]['2030'].expected_case_incidence)/progressCases) * 100).toFixed(2);
            let pdeaths = progressJson?.[key]?.[year]?.deaths;
            if(pdeaths === "0") {
                pdeaths = "100"
            }
          percent.deaths = (((projectionJson[key]['2015'].expected_mortality_rate - projectionJson[key]['2030'].expected_mortality_rate)/pdeaths) * 100).toFixed(2)
          percent.cases = progressJson?.[key]?.['2022']?.cases
          percent.deaths = progressJson?.[key]?.['2022']?.deaths
          percent.cases_status = progressJson?.[key]?.['2022']?.cases_status
          percent.cases_color = evalColor(percent.cases_status)
          percent.mortality_status = progressJson?.[key]?.['2022']?.mortality_status
            percent.deaths_color = evalColor(percent.mortality_status)
            percent.curr_year = year
            percent.iso2 = progressJson?.[key]?.['iso2']
            percent.official_name = progressJson[key]['official_name']
            percent.who_region = progressJson?.[key]?.['who_region']

            percentages.push(percent);
        }
    }
    return percentages;
}

function getCWAverage(percentages, year) {

    const avg = {};
    avg.curr_year = year;

    avg.deaths = (percentages.reduce(function (sum, obj) {
        return sum + obj.deaths*1;
    }, 0)/percentages.length).toFixed(2);

    avg.cases = (percentages.reduce(function (sum, obj) {
        return sum + obj.cases*1;
    }, 0)/percentages.length).toFixed(2);

    avg.deaths_color = evalColor(avg.deaths);
    avg.cases_color = evalColor(avg.cases);
    return avg;
}

function getCWCountries(percentages) {
    return percentages.map((item)=> item.official_name)
}

export {
    csvToJson,
    csvToArray,
    normalizeTrackerData,
    normalizeToPercentage,
    getCWCountries,
    getCWAverage
}
