import React from 'react';
import NewLineChart from './NewLineChart';

import '../style/country-popup.scss'

const CountryPopup = (props) => {

    let {closePopup, onCountryClick, official_name, iso2, who_region, ...chartProps} = props;
    return(
        <div className="country-popup">
            <div className="close-popup" onClick={ closePopup }></div>
            <div className="popup-header">
                { official_name }
            </div>
            <div className="popup-body">
                <NewLineChart isGrid={true} isPopup={true} isCases={props.isCases} append={props.view} handleToggle={props.handleToggle} country = {official_name}  csvData={props.progressData} csvDataProjection={props.projectionData} />
           </div>
            <div className="more-about-country" onClick={ () => onCountryClick(official_name) }>
                MORE ABOUT { official_name.toString().toUpperCase() }<span className="country-arrow"></span>
            </div>
        </div>
    )
}

export default CountryPopup;