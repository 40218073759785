import React from "react";

const MapZoom = ({ zoomIncrease, zoomDecrease }) => {
  return (
    <svg
      width="58px"
      height="114px"
      viewBox="0 0 58 114"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Map/zoom</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Map/zoom"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Zoom">
          <rect
            id="Rectangle-32"
            stroke="#B5B5B5"
            fill="#FFFFFF"
            strokeLinecap="square"
            x="0"
            y="0"
            width="58"
            height="114"
          />
          <rect
            fill="#fff"
            id="ic_zoom_in"
            x="5"
            y="1"
            onClick={zoomIncrease}
            width="50"
            height="50"
          />
          <rect
            fill="#fff"
            id="ic_zoom_out"
            x="5"
            y="60"
            onClick={zoomDecrease}
            width="50"
            height="50"
          />
          <rect
            onClick={zoomDecrease}
            fill="#000000"
            x="18"
            y="82"
            width="22"
            height="6"
          />
          <g
            onClick={zoomIncrease}
            transform="translate(18.000000, 18.000000)"
            fill="#000000"
          >
            <path
              d="M8,8 L8,0 L14,0 L14,8 L22,8 L22,14 L14,14 L14,22 L8,22 L8,14 L0,14 L0,8 L8,8 Z"
              id="Combined-Shape"
            />
          </g>
          <path
            d="M11,57 L47,57"
            id="separator"
            stroke="#B5B5B5"
            strokeLinecap="square"
          />
        </g>
      </g>
    </svg>
  );
};

export default MapZoom;
