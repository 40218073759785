import React, { Component } from "react";
import MapKeyMbl from "../newcomps/MapKeyMbl";
import NewLineChart from "../newcomps/NewLineChart";
import StickyBar from "../newcomps/StickyBar";
import fetchAppData from '../services/service';
import "../style/country-details-page.scss";
import EXsvgBorderLeft from './EXsvgBorderLeft';
import HTMLLoader from "./HTMLLoader";
import NumbersSection from './NumbersSection';
import { CURRENT_YEAR } from "../constants";
class CountryDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCountryList: [],
      currentCuntry: {},
      currentCuntryIndex: 0,
      isShowPreviousCountry: false,
      isShowNextCountry: true,
      colorList: {},
      percentageList: [],
      barCountryObj: {},
      csvData: [],
      csvDataProjection: [],
      isCases: props.isCases,
      view: props.view
    }
    this.changeCountry = this.changeCountry.bind(this);
    this.getColorList = this.getColorList.bind(this);
    this.setBarChartObj = this.setBarChartObj.bind(this);
    this.backToMap = this.backToMap.bind(this);
    this.calculatePercentage = this.calculatePercentage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    fetchAppData().then((result) => {
      const { progressChartData, projectionChartData } = result;
      this.setState({
        csvData: progressChartData,
        csvDataProjection: projectionChartData
      })
      this.formBarCharts(
        progressChartData,
        projectionChartData
      );
    })
    let scrollpos = window.scrollY;
    let stickybarContainer = document.querySelector(".mbl-view");
    window.addEventListener('scroll', function () {
      scrollpos = window.scrollY;
      if (scrollpos >= 280 && window.innerWidth > 768) {
        stickybarContainer.classList.add("is-sticky");
      }
      else {
        stickybarContainer.classList.remove("is-sticky");
      }
    });
  }

  //Form charts as per received csv data..
  formBarCharts(tracker_data_progress_csv, tracker_data_projection_csv) {
    let that = this;
    let csvCountryList = [];
    let finalCsvCountryList = [];
    let data1 = [];
    let data2 = [];
    let deathsdata2 = [];
    let years = [];
    let jindex = 0;
    let csvdata;
    if (typeof document != 'undefined') {
      // d3.csv(tracker_data_progress_csv).then(function (csvdata) {
      csvdata = tracker_data_progress_csv;
      if (csvdata[csvdata.length - 1]['official_name'] === undefined) { csvdata.pop(); }
      for (let i = 0; i < csvdata.length; i++) {
        if (csvCountryList.indexOf(csvdata[i].official_name) < 0) {
          csvCountryList.push(csvdata[i].official_name);
          if (finalCsvCountryList.length) {
            finalCsvCountryList[jindex]['data1'] = data1
            finalCsvCountryList[jindex]['data2'] = data2
            finalCsvCountryList[jindex]['deathsdata2'] = deathsdata2
          }

          data1 = [];
          data2 = [];
          deathsdata2 = [];
          data1.push(csvdata[i].popatrisk_total)
          data2.push(csvdata[i].cases)
          deathsdata2.push(csvdata[i].deaths)
          let element = {};
          element.official_name = csvdata[i].official_name;
          element.iso2 = csvdata[i].iso2;
          element.year = csvdata[i].year;
          element.popatrisk_total = csvdata[i].popatrisk_total;
          element.cases = csvdata[i].cases;
          element.deaths = csvdata[i].deaths;
          element.case_incidence2 = csvdata[i].case_incidence2;
          element.mortality_rate = csvdata[i].mortality_rate;
          element.data1 = [];
          element.data2 = [];
          element.deathsdata2 = [];
          element.data3 = [];
          element.deathsdata3 = [];
          element.data11 = [];
          element.years = [];
          finalCsvCountryList.push(element);
        } else {
          for (var j = 0; j < finalCsvCountryList.length; j++) {
            if (finalCsvCountryList[j].iso2 === csvdata[i].iso2) {
              if (finalCsvCountryList[j].year < csvdata[i].year) {
                finalCsvCountryList[j]['year'] = csvdata[i].year
                finalCsvCountryList[j]['popatrisk_total'] = csvdata[i].popatrisk_total
                finalCsvCountryList[j]['cases'] = csvdata[i].cases
                finalCsvCountryList[j]['deaths'] = csvdata[i].deaths
                finalCsvCountryList[j]['case_incidence2'] = csvdata[i].case_incidence2;
                finalCsvCountryList[j]['mortality_rate'] = csvdata[i].mortality_rate;
              }
              jindex = j;
              data1.push(csvdata[i].popatrisk_total)
              data2.push(csvdata[i].cases)
              deathsdata2.push(csvdata[i].deaths)
            }
          }
        }

        if (years.indexOf(csvdata[i].year) < 0) {
          years.push(csvdata[i].year);
        }

        if (finalCsvCountryList.length && i === csvdata.length - 1) {
          finalCsvCountryList[jindex]['data1'] = data1
          finalCsvCountryList[jindex]['data2'] = data2
          finalCsvCountryList[jindex]['deathsdata2'] = deathsdata2
        }
      }
      let listData = finalCsvCountryList.sort((a, b) => (a.official_name > b.official_name) ? 1 : ((b.official_name > a.official_name) ? -1 : 0));
      let currentCuntryObj = {};
      let selCountry = that.props.match.params.selectedCountry;
      var result = listData.filter((obj, i) => {
        that.setState({ isShowPreviousCountry: (i >= 0 && selCountry) ? true : false });
        // eslint-disable-next-line eqeqeq
        return obj.official_name === selCountry && obj.year == CURRENT_YEAR
      });
      //result[0] ? that.setState({ currentCuntry: result[0] }) : that.setState({ currentCuntry: listData[0] });
      currentCuntryObj = result[0] ? result[0] : listData[0];
      that.setState({ currentCuntry: currentCuntryObj });
      that.setState({ allCountryList: listData });
      let index = listData.findIndex(obj => obj.official_name === currentCuntryObj.official_name);
      that.setState({ currentCuntryIndex: index });
      if (this.state.allCountryList.official_name === "commonwealth") {
        //this.state.allCountryList[this.state.currentCuntryIndex]
      }
      // });

      let final_projection_csv_data = [];
      let colorList = {};
      let data3 = [];
      let deathsdata3 = [];
      let data11 = [];
      // d3.csv(tracker_data_projection_csv).then(function (csvdata) {
      csvdata = tracker_data_projection_csv;
      if (csvdata[csvdata.length - 1]['official_name'] === undefined) { csvdata.pop(); }
      for (let i = 0; i < finalCsvCountryList.length; i++) {
        for (let j = 0; j < csvdata.length; j++) {
          if (finalCsvCountryList[i].iso2 === csvdata[j].iso2 && finalCsvCountryList[i].year === csvdata[j].year) {
            let element = {};
            element.iso2 = csvdata[j].iso2;
            element.year = csvdata[j].year;
            element.gts_cases = csvdata[j].gts_cases;
            element.gts_deaths = csvdata[j].gts_deaths;
            final_projection_csv_data.push(element);
          }

          if (finalCsvCountryList[i].iso2 === csvdata[j].iso2) {
            data3.push(csvdata[j].gts_cases)
            deathsdata3.push(csvdata[j].gts_deaths)
          }

          if (finalCsvCountryList[i].iso2 === csvdata[j].iso2 && csvdata[j].year >= finalCsvCountryList[i].year) {
            data11.push(csvdata[j].popatrisk_total)
          }
          if (years.indexOf(csvdata[j].year) < 0) {
            years.push(csvdata[j].year);
          }
        }
        finalCsvCountryList[i]['data3'] = data3;
        finalCsvCountryList[i]['deathsdata3'] = deathsdata3;
        finalCsvCountryList[i]['data11'] = data11;
        finalCsvCountryList[i]['years'] = years;
        data3 = [];
        deathsdata3 = [];
        data11 = [];
      }
      colorList = that.getColorList(finalCsvCountryList, final_projection_csv_data);
      that.setBarChartObj();
      // });
    }
  }

  getColorList(finalCsvCountryList, final_projection_csv_data) {
    let percentageList = [];
    for (let i = 0; i < finalCsvCountryList.length; i++) {
      let percentage_deaths = 0;
      let percentage_cases = (finalCsvCountryList[i].cases / (final_projection_csv_data?.[i]?.gts_cases * 2)) * 100;
      if (final_projection_csv_data[i]?.gts_deaths !== 0) {
        percentage_deaths = (finalCsvCountryList[i].deaths / (final_projection_csv_data?.[i]?.gts_deaths * 2)) * 100;
      } else {
        percentage_deaths = finalCsvCountryList[i].deaths * 100;
      }
      let element = {};
      element.official_name = finalCsvCountryList[i].official_name;
      element.iso2 = finalCsvCountryList[i].iso2;
      element.percentage_cases = Math.round(percentage_cases);
      element.percentage_deaths = Math.round(percentage_deaths);
      percentageList.push(element)
    }
    let percentageListData = percentageList.sort((a, b) => (a.official_name > b.official_name) ? 1 : ((b.official_name > a.official_name) ? -1 : 0));
    this.setState({ percentageList: percentageListData })
    let colorList = {};
    for (let i = 0; i < percentageList.length; i++) {
      if (percentageList[i].percentage_cases <= 50 && percentageList[i].percentage_deaths <= 50) {
        colorList[percentageList[i].official_name] = '#38c712'
      } else if (percentageList[i].percentage_deaths > 50 && percentageList[i].percentage_cases < 50) {
        colorList[percentageList[i].official_name] = '#fbde0e'
      } else if (percentageList[i].percentage_cases > 50 && percentageList[i].percentage_deaths < 50) {
        colorList[percentageList[i].official_name] = '#e76126'
      } else {
        colorList[percentageList[i].official_name] = '#b10a0a'
      }
    }

    return colorList;
  }

  changeCountry(name) {
    this.state.allCountryList.splice(this.state.allCountryList.findIndex(a => a.official_name === "Commonwealth"), 1)
    document.querySelector(".App").classList.add("loader");
    const { history } = this.props;
    const path = {
      pathname: "/",
      changeToCountry: name
    };
    history.push(path);
  }

  setBarChartObj(currentObj) {
    const { currentCuntry, percentageList, currentCuntryIndex } = this.state;
    let country = currentObj ? currentObj : currentCuntry;
    let result = percentageList.filter(data => {
      return data.official_name === country.official_name
    })
    this.setState({ barCountryObj: result });
  }

  backToMap() {
    document.querySelector(".App").classList.add("loader");
    setTimeout(() => {
      document.querySelector(".App").classList.remove("loader");
      const { history } = this.props;
      const path = {
        pathname: "/"
      };
      history.push(path);
    }, 800);
    document.querySelector(".App").classList.add("loader-leave");

  }

  // handleToggle=() => {
  //     this.setState(prevState => ({
  //         isCases: !prevState.isCases
  //     }),()=> {
  //         const mapState = !this.state.isCases ? "cases" : "deaths"
  //         this.changeView(mapState)
  //     });
  // }

  // changeView=(view)=> {
  //     this.setState({view: view})
  // }

  calculatePercentage(currentValue, prevValue) {
    const checkValue = prevValue === 0 ? 1 : prevValue;
    return currentValue >= prevValue
      ? ([(currentValue - prevValue) / checkValue] * 100).toFixed(2)
      : ([(prevValue - currentValue) / checkValue] * 100).toFixed(2);
  }

  render() {


    const { allCountryList, currentCuntry, isShowPreviousCountry, isShowNextCountry, currentCuntryIndex, barCountryObj, csvData, csvDataProjection, view } = this.state;
    const currentObject = Object.keys(currentCuntry).length > 0 ? currentCuntry : null;
    const country = currentCuntry.official_name;
    const countryIso2 = currentObject ? currentObject.iso2 : '';
    const flagClass = "flagbox flag-icon-" + countryIso2.toString().toLowerCase();
    let checkForCommonwealth = allCountryList.findIndex(a => a.official_name === "Commonwealth")
    let checkForLanka = allCountryList.findIndex(b => b.official_name === "Sri Lanka")
    if (checkForCommonwealth >= 0) {
      this.state.allCountryList.splice(this.state.allCountryList.findIndex(a => a.official_name === "Commonwealth"), 1)
    }
    if (checkForLanka >= 0) {
      this.state.allCountryList.splice(this.state.allCountryList.findIndex(a => a.official_name === "Sri Lanka"), 1)
    }
    return (
      <>
        <div className="container-fluid country-tracker-header">
          <div className="container">
            <div className="row">
              <div className="hero-title text-center col-md-12">
                <h1 className="">COMMONWEALTH MALARIA TRACKER</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="country-detail">
          { currentObject &&
            <div className="texture-background">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 back-to-map" onClick={ this.backToMap } tabIndex="0">Back to all countries</div>
                  <div className="col-md-12 country-flag-block">
                    { currentObject && <div className={ flagClass }></div> }
                    { currentObject && <h1 className="country-name">{ currentObject.official_name }</h1> }
                  </div>
                  <div className="col-md-12 line-chart-section">
                    <div className="deaths-line-chart">
                      <NewLineChart isCases={ this.props.isCases } isDetailpage={ true } append={ this.props.view } country={ country } csvData={ csvData } csvDataProjection={ csvDataProjection } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <StickyBar className="sticky-footer" handleToggle={ this.props.handleToggle } isCases={ this.props.isCases } />
          <MapKeyMbl className="sticky-footer" handleToggle={ this.props.handleToggle } isCases={ this.props.isCases } />

          { currentObject &&
            <div className="container">
              <div className="row">
                <div className="col-md-12 right-section">
                  <NumbersSection currentCountry={ currentObject } calculatePercentage={ this.calculatePercentage } />
                  <div className="about-section">
                    <div className="section-1">
                      <h3>More resources</h3>
                      <EXsvgBorderLeft />
                      <div>
                        <h5>Commonwealth Malaria report</h5>
                      </div>
                      <div>
                        <p>Learn more about the progress to end malaria in the 2024 Commonwealth Malaria Report <a href="https://malarianomore.org.uk/sites/default/files/Malaria_Report_2024_DIGITAL.pdf" target="_blank" rel="noopener noreferrer">here</a></p>
                      </div>
                      <HTMLLoader folder="about" country={ currentCuntry.official_name } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="country-navigation">
            <div className="navigation-container">
              { isShowPreviousCountry && allCountryList && <div className="previous" onClick={ () => this.changeCountry(allCountryList[currentCuntryIndex - 1]['official_name'].toString()) } onKeyPress={ this.changeCountry.bind(this) } tabIndex="0">{ currentCuntryIndex > 0 ? allCountryList[currentCuntryIndex - 1]['official_name'].toString().toUpperCase() : '' }</div> }

              { isShowNextCountry && allCountryList && <div className="next" onClick={ () => this.changeCountry(allCountryList && allCountryList[currentCuntryIndex + 1]['official_name'].toString()) } onKeyPress={ this.changeCountry.bind(this) } tabIndex="0">{ currentCuntryIndex < allCountryList.length - 1 ? allCountryList && allCountryList[currentCuntryIndex + 1]['official_name'].toString().toUpperCase() : '' }</div> }
            </div>
          </div>
          { allCountryList &&
            <div className="country-index">SHOWING
              <span className="current-index"> { currentCuntryIndex + 1 } </span>
              OF
              <span className="total-index"> { allCountryList.length } </span>
            </div>
          }
        </div>
      </>
    );
  }
}

export default CountryDetailsPage;
