import React from 'react';

import '../style/progress-in-action.scss';
import '../style/tracker-header.scss';

const TrackerHeader = (props) => {
  return (
    <div className="container-fluid tracker-header">
      <div className="container">
        <div className="row">
          <div className="hero-title text-center col-md-12">
            <h1 className="">COMMONWEALTH MALARIA TRACKER</h1>
            <p className="sub-title">At the 2022 Commonwealth Heads of Government Meeting in Kigali, Rwanda leaders committed to ending malaria in the Commonwealth by 2030 in line with global, regional and national targets. Using data from the latest WHO World Malaria Report, the Commonwealth Malaria Tracker presents progress across all Commonwealth countries towards this commitment using the global Sustainable Development Goal target. Scroll down to view progress and explore countries in more-depth.
            </p>
            <button className="cta cta-how" onClick={ props.onCTAClick }>HOW THE TRACKER WORKS</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrackerHeader;