import React from 'react';
import {REGION_LIST, ui_breakpoint} from '../constants'
import useWindowWidth from '../hooks/useWindowWidth';

import '../style/region-filter.scss'

const RegionFilter = (props) => {

    const width = useWindowWidth();
    return (width >= ui_breakpoint ? <DesktopFilter {...props}></DesktopFilter> : <MobileFilter {...props}></MobileFilter>)
}

export default RegionFilter;


const DesktopFilter = (props) => {
    return (
        <div className="col-md-12 region-filter">
                    FILTER BY WHO REGION 
                    { 
                        Object.keys(REGION_LIST).map((item, i) => {
                            let value = REGION_LIST[item]
                            return <label key={i}>
                                        <input
                                            type="radio"
                                            name="regions"
                                            value={value}
                                            checked={props.selectedOption === value}
                                            onChange={props.onChange}
                                            className="check-input"
                                        /><span>{value}</span>
                                    </label>
                        }) 
                    }
                </div>
    )
}

const MobileFilter = (props) => {
    return (
        <div className="col-md-12 region-filter">

            <select id="select-region" className="select-region" onChange={props.onChange} defaultValue="" selected={props.selectedOption}>
                <option value="" disabled>FILTER BY WHO REGION </option>
                { Object.keys(REGION_LIST).map((item, i) => (
                    <option key={ i } value={ REGION_LIST[item] } label= { REGION_LIST[item] }>
                        { REGION_LIST[item] }
                    </option>
                )) }
            </select>
        </div>
    )
}

