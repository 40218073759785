import React from 'react'
import MapKey from './MapKey';
import MapZoom from '../components/MapZoom';
import worlddata from "../mockdata/worldData.json";
import * as d3 from "d3";

import colorList from '../services/colorlist'
import CountryPopup from './CountryPopup';

import '../style/world-map.scss'
import MapKeyLegend from './MapKeyLegend';
import StickyBar from './StickyBar';
import MapKeyMbl from './MapKeyMbl'
import TogglePopUp from './TogglePopUp';


class WorldMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            countrySelected: null,
            isSticky: 'is-sticky'
        }
        this.currentScale = 1;
        this.mapUpdate = false;

        this.zoomOut = this.zoomOut.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomToCenter = this.zoomToCenter.bind(this)
        this.handleMapClick = this.handleMapClick.bind(this)
        this.closePopup = this.closePopup.bind(this)
        this.centerMap = {};
        this.width = 1200;
        this.height = 590;

        this.zoom  = '';
        this.svg = '';
        this.centered = '';
        this.projection = '';
        this.path = "";
        this.isPopupShow = false;
        this.zoomConfiuration = d3.zoom().scaleExtent([1, 20]).translateExtent([[0, 0], [this.width, this.height]]).on("zoom", function () {
            d3.select(".center-map").attr("transform", d3.event.transform)
        });
    }

    componentDidMount() {
        this.createMap();
        let scrollpos = window.scrollY;
        let stickybarContainer = document.querySelector(".mbl-view");
        let stickytogglePopup = document.querySelector(".toggle-popup");
        window.addEventListener('scroll', function(){ 
            scrollpos = window.scrollY;
            if(scrollpos >= 498 && window.innerWidth > 768 ){
                stickybarContainer.classList.add("is-sticky");
               
            }
            if(scrollpos >= 400 && window.innerWidth < 1400) {
                stickytogglePopup.classList.add("stick-popup");
            }
            else {
                stickybarContainer.classList.remove("is-sticky");
                stickytogglePopup.classList.remove("stick-popup");
            }
        });
    
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        (this.props !== nextProps && this.props.view !== nextProps.view) ?
        this.mapUpdate = true : this.mapUpdate = false
        if(this.mapUpdate === true && this.state.countrySelected !== null){
            //this.closePopup();
            let selectedCountry = this.g.select('#'+this.state.countrySelected.postal).data()[0];
            this.closePopup();
            var that = this;
            setTimeout(function(){
                that.handleMapClick(selectedCountry);
            })
        }
        
        return true;

    }

    componentDidUpdate() {
        this.mapUpdate && this.createMap();
        
    }

    closePopup() {
        this.isPopupShow = false;
        this.svg.call(this.zoom);
        d3.select('svg.map-svg').attr("class","map-svg");
        this.handleMapClick(null)
        this.setState({countrySelected: null})
    }
    
    handleMapClick (obj) {
        if(!this.isPopupShow) {
            if (obj) {
                //if(this.props.percentages[obj.properties['admin']]) {
                let country = getCountryByName(this.props.percentages, obj.properties['admin'])
                if(country) {
                    this.isPopupShow = true;
                    d3.select('svg.map-svg').attr("class","map-svg popup-opened");
                    country['postal'] = obj.properties.postal;
                    this.setState({countrySelected: country})
                }
            } else {
                console.log('No country data matched from percentagelist')
            }
            if (!obj || this.isPopupShow) {
                this.zoomToCenter(obj);
            }
        }
    }
    resetMapZoom(){
        this.centered = null;
        this.svg.transition().duration(750).call(
            this.zoom.transform,
            d3.zoomIdentity,
            d3.zoomTransform(this.svg.node()).invert([this.width / 2, this.height / 2])
        )
    }


    clickedMapZoom(d){
        const [[x0, y0], [x1, y1]] = this.path.bounds(d);
 
 
        this.svg.transition().duration(750).call(
        this.zoom.transform,
        d3.zoomIdentity
            .translate(this.width / 2, this.height / 2)
            .scale(Math.min(8, 0.9 / Math.max((x1 - x0) / this.width, (y1 - y0) / this.height)))
            .translate(-(x0 + x1) / 2, -((y0 + y1) / 2)+20)
        );
    }
  
    /**
     * method to zoom in and center the country clicked on
     * @param {*} d - geo data about the country cliked
     */
    zoomToCenter(d) {
        var x, y, k;
        if (d && this.centered !== d) {
            var centroid = this.path.centroid(d);
            x = centroid[0];
            y = centroid[1];
            k = 4;
            this.centered = d;
            this.clickedMapZoom(d);
            this.svg.on('.zoom', null);
        } else {
            x = this.width / 2;
            y = this.height / 2;
            k = 1;
            //this.resetMapZoom();
        }
    }

    createMap() {
        this.zoom = this.zoomConfiuration;
        
        this.svg = d3.select(this.refs.anchor);
         
        this.svg.select('g').remove();
        let width = 1200,
            height = 590;

        this.projection = d3.geoMercator()
            .center([0, 0]) 
        .translate([this.width / 2, this.height / 2 + 100]);

        var path = d3.geoPath(this.projection);
        this.path = path;
        d3.select(".world-map svg")
            .attr("viewBox", "0 0 1200 590");

        this.svg.append("rect")
            .attr("width", width)
            .attr("height", height)
            .attr("class", "background")

        this.centerMap = this.svg.append("g")
            .attr("class", "center-map")
            .append("g")
            .attr("id", "states")
            this.g = this.centerMap;
        this.centerMap.selectAll("path")
            .data(worlddata.features)
            .enter().append("path")
            .attr("d", path)
            .attr("id", function (d) { return d.properties.postal; })
            .on("click", (window.innerWidth > 1025)?this.handleMapClick:null)

            this.centerMap.selectAll("text")
            .data(worlddata.features)
            .enter().append("text")
            .attr("transform", function (d) { return "translate(" + path.centroid(d) + ")"; })
            .attr("id", function (d) { return 'label-' + d.properties.postal; })
            .attr("dy", ".35em")

            this.centerMap.selectAll("path")
                    .style("fill", d => getColor(this.props.percentages, colorList, d.properties['admin'], this.props.view))
                    .attr("class", (d) => {
                        let country = getCountryByName(this.props.percentages, d.properties['admin'])
                        if(country) {
                            return colorList[d.properties.admin] ? "states-class" : ""
                        }
                    });

            this.svg.call(this.zoom);

    }

    
    zoomIn () {
        if(!this.isPopupShow){
            this.svg.transition().call(this.zoom.scaleBy, 2);
        }
    }

    zoomOut () {
        if(!this.isPopupShow){
            this.svg.transition().call(this.zoom.scaleBy, 0.5);
        }
    }

    render() {
        return (
            <>
                <div className="container">       
                    <div className="row">
                        <div className="col-md-12 text-center">
                        </div>
                    </div>
                    <div className="row">
                        <MapKeyLegend handleToggle={this.props.handleToggle} isCases={this.props.isCases} />
                        <MapKey handleToggle={this.props.handleToggle} isCases={this.props.isCases}/>
                        <div className="col-md-12 world-map">
                            <canvas width="1200" height="590"></canvas>                                
                            <svg className="map-svg">
                                <g ref="anchor" />
                            </svg>
                            <div className="zoom-buttons d-none d-md-block">
                                <MapZoom zoomIncrease={this.zoomIn} zoomDecrease={this.zoomOut} />
                            </div>
                            
                            { this.state.countrySelected  && <CountryPopup
                                closePopup={this.closePopup} 
                                handleCountryClickLink={f=>f} 
                                currentCountryObj={this.state.countrySelected}
                                {...this.state.countrySelected}
                                progressData={this.props.progressData} projectionData ={ this.props.projectionData}
                                handleToggle={this.props.handleToggle}
                                view={this.props.view}
                                isCases={this.props.isCases}
                                isPopup={this.props.isPopup}
                                onCountryClick={()=>this.props.onCountryClick(this.state.countrySelected)}
                            />}
                        </div>
                    </div>
                    {/* <div className="row">
                        <CountrySelector/>
                    </div> */}
                </div>
                <StickyBar className="sticky-footer" handleToggle={this.props.handleToggle} isCases={this.props.isCases} />
                <MapKeyMbl className="sticky-footer" handleToggle={this.props.handleToggle} isCases={this.props.isCases}/>
                <TogglePopUp/>
                </>
        )
    }
}


function getCountryByName(list, name) {
    let result = list.filter((item)=>item.official_name === name);
    return result[0]
}

function getColor(list, colorlist, name, view) {
    let country = getCountryByName(list, name)
    let prop = (view === 'cases') ? 'cases_color':'deaths_color'
    return (country) ? country[prop] : (colorlist[name])? colorlist[name] : '#d5d5d5'
}

export default WorldMap;