import React from "react";
import TextTransition from "./TextTransition";
import EXsvgBorderLeft from "./EXsvgBorderLeft";
import { CURRENT_YEAR } from "../constants";

const NumbersSection = props => {
  const { currentCountry = [] } = props;
  const population = +(currentCountry.popatrisk_total).replace(/ +/g, "");
  const cases = +(currentCountry.cases).replace(/ +/g, "");
  const case_incidence = parseFloat(currentCountry.case_incidence2).toFixed(2);
  const mortality = parseFloat(currentCountry.mortality_rate).toFixed(2);

  return (
    <div className="by-numbers-section border-bottom-desk">
      <h3>PROGRESS BY THE NUMBERS</h3>
      <EXsvgBorderLeft />
      <div className="row number-section-row">
      <div className="col-md-4 vertical-border number-section-block">
        <p className="population-at-risk">POPULATION AT RISK { CURRENT_YEAR }</p>
        <TextTransition
          append={"popatrisk_total"}
          currentCuntry={population}
        />
      </div>
      <div className="col-md-4 vertical-border number-section-block number-section-row">
          <p className="malaria-cases">MALARIA CASES { CURRENT_YEAR }</p>
        <TextTransition append={"cases"} currentCuntry={cases} />
      </div>
      <div className="col-md-4 border-bottom-mbl number-section-block">
          <p className="malaria-deaths">MALARIA DEATHS { CURRENT_YEAR }</p>
        <TextTransition
          append={"deaths"}
          currentCuntry={currentCountry.deaths}
        />
      </div>
      </div>
      <div className="row align-middle-desktop number-section-row">
        <div className="col-md-4 vertical-border number-section-block">
          <p className="incidence-rate-detail">INCIDENCE RATE { CURRENT_YEAR }</p>
          <div className="country-detail-no">{case_incidence}</div>
          <p>Per 1,000 at risk </p>
        </div>
        <div className="col-md-4 border-bottom-mbl number-section-block">
          <p className="mortality-rate-detail">MORTALITY RATE { CURRENT_YEAR }</p>
          <div className="country-detail-no">{mortality}</div>
          <p>Per 100,000 at risk</p>
        </div>
      </div>
    </div>
  );
};

export default NumbersSection;
