const colorlist = { 
    "Afghanistan": "#D6D6D6",
    "Algeria": "#D6D6D6",
    "Angola": "#D6D6D6",
    "Antigua and Barbuda": "#B3E8ED",
    "Argentina": "#D6D6D6",
    "Australia": "#B3E8ED",
    "The Bahamas": "#B3E8ED",
    "Bangladesh": "#78CF5B",
    "Barbados": "#B3E8ED",
    "Belize": "#78CF5B",
    "Benin": "#D6D6D6",
    "Bhutan": "#D6D6D6",
    "Bolivia (Plurinational State of)": "#D6D6D6",
    "Botswana": "#D43225",
    "Brazil": "#D6D6D6",
    "Brunei Darussalam": "#B3E8ED",
    "Burkina Faso": "#D6D6D6",
    "Burundi": "#D6D6D6",
    "Cabo Verde": "#D6D6D6",
    "Cambodia": "#D6D6D6",
    "Cameroon": "#D43225",
    "Canada": "#B3E8ED",
    "Central African Republic": "#D6D6D6",
    "Chad": "#D6D6D6",
    "China": "#D6D6D6",
    "Colombia": "#D6D6D6",
    "Comoros": "#D6D6D6",
    "Costa Rica": "#D6D6D6",
    "Cyprus": "#B3E8ED",
    "Côte d’Ivoire": "#D6D6D6",
    "Democratic People’s Republic of Korea": "#D6D6D6",
    "Democratic Republic of the Congo": "#D6D6D6",
    "Djibouti": "#D6D6D6",
    "Dominica": "#B3E8ED",
    "Dominican Republic": "#D6D6D6",
    "Ecuador": "#D6D6D6",
    "El Salvador": "#D6D6D6",
    "Equatorial Guinea": "#D6D6D6",
    "Eritrea": "#D6D6D6",
    "Eswatini": "#D6D6D6",
    "Ethiopia": "#D6D6D6",
    "Fiji": "#B3E8ED",
    "French Guiana": "#D6D6D6",
    "Gabon": "#D6D6D6",
    "The Gambia": "#fbde0e",
    "Ghana": "#D43225",
    "Grenada": "#B3E8ED",
    "Guatemala": "#D6D6D6",
    "Guinea": "#D6D6D6",
    "Guinea-Bissau": "#D6D6D6",
    "Guyana": "#D43225",
    "Haiti": "#D6D6D6",
    "Honduras": "#D6D6D6",
    "India": "#78CF5B",
    "Indonesia": "#D6D6D6",
    "Iran (Islamic Republic of)": "#D6D6D6",
    "Jamaica": "#B3E8ED",
    "Kenya": "#D43225",
    "Kingdom of Eswatini": "#D43225",
    "Kiribati": "#B3E8ED",
    "Lao People’s Democratic Republic": "#D6D6D6",
    "Lesotho": "#B3E8ED",
    "Liberia": "#D6D6D6",
    "Madagascar": "#D6D6D6",
    "Mainland": "#D6D6D6",
    "Malawi": "#D43225",
    "Malaysia": "#fbde0e",
    "Mali": "#D6D6D6",
    "Malta": "#B3E8ED",
    "Mauritania": "#D6D6D6",
    "Mauritius": "#B3E8ED",
    "Mayotte": "#D6D6D6",
    "Mexico": "#D6D6D6",
    "Mozambique": "#D43225",
    "Myanmar": "#D6D6D6",
    "Namibia": "#D43225",
    "Nauru": "#B3E8ED",
    "Nepal": "#D6D6D6",
    "New Zealand": "#B3E8ED",
    "Nicaragua": "#D6D6D6",
    "Niger": "#D6D6D6",
    "Nigeria": "#e76126",
    "Pakistan": "#D43225",
    "Panama": "#D6D6D6",
    "Papua New Guinea": "#D43225",
    "Paraguay": "#D6D6D6",
    "Peru": "#D6D6D6",
    "Philippines": "#D6D6D6",
    "Republic of Congo": "#D6D6D6",
    "Republic of Korea": "#D6D6D6",
    "Rwanda": "#D43225",
    "Saint Lucia": "#B3E8ED",
    "Vatican": "#B3E8ED",
    "Samoa": "#B3E8ED",
    "Sao Tome and Principe": "#D6D6D6",
    "Saudi Arabia": "#D6D6D6",
    "Senegal": "#D6D6D6",
    "Seychelles": "#B3E8ED",
    "Sierra Leone": "#D43225",
    "Singapore": "#B3E8ED",
    "Solomon Islands": "#D43225",
    "Somalia": "#D6D6D6",
    "South Africa": "#D43225",
    "South Sudan": "#D6D6D6",
    "Sri Lanka": "#B3E8ED",
    "Saint Kitts and Nevis": "#B3E8ED",
    "Saint Vincent and the Grenadines": "#B3E8ED",
    "Sudan": "#D6D6D6",
    "Suriname": "#D6D6D6",
    "Thailand": "#D6D6D6",
    "Timor-Leste": "#D6D6D6",
    "Togo": "#D6D6D6",
    "Tonga": "#B3E8ED",
    "Trinidad and Tobago": "#B3E8ED",
    "Tuvalu": "#B3E8ED",
    "Uganda": "#D43225",
    "United Kingdom": "#B3E8ED",
    "United Republic of Tanzania": "#D43225",
    "Vanuatu": "#D43225",
    "Venezuela (Bolivarian Republic of)": "#D6D6D6",
    "Vietnam": "#D6D6D6",
    "WESTERN": "#D6D6D6",
    "Yemen": "#D6D6D6",
    "Zambia": "#D43225",
    "Zanzibar": "#D6D6D6",
    "Zimbabwe": "#D6D6D6",
}

export default colorlist;