import React from 'react'

import '../style/country-selector.scss'

const CountrySelector = (props) => {
    return(
        <div className="container country-selector">
            <div className="row text-center">
                <div className="col-sm-12 col-md-8 col-lg-9">
                    <label htmlFor="select-country">
                        <span className="d-md-none">SELECT A COUNTRY FROM THE DROPDOWN TO LEARN MORE</span>
                        <span className="d-none d-md-block">SELECT A COUNTRY ON THE MAP TO LEARN MORE, OR CHOOSE FROM DROPDOWN</span>
                    </label>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-3">
                    <select id="select-country" onChange={props.onCountryChange} defaultValue="" selected={props.selectedCountry}>
                        <option value="" disabled>COUNTRY</option>
                        { props.countries.map((country, i) => (
                            <option key={ i } value={ country } label= { country }>
                                { country }
                            </option>
                        )) }
                    </select>
                </div>
            </div>
        </div>
    )
}

export default CountrySelector;