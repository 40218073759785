import React from "react";
import "../style/map-key.scss";
import "../style/map-key-legend.scss";

const MapKeyLegend = (props) => {
    return (
        <div className="col-md-12 map-key-desc">
            <p>THE COMMONWEALTH:</p>
            <ul className="legend">
                <li><span className="key malaria-free"></span>MALARIA FREE</li>
                <li><span className="key on-track"></span>ON-TRACK</li>
                <li><span className="key off-track"></span>OFF-TRACK</li>
            </ul>
            {!props.isCases?
            <div className="map-toggle-key cases">SHOWING CASES</div>:
            <div className="map-toggle-key deaths">SHOWING DEATHS</div>}
        </div>
    );
}

export default MapKeyLegend;



