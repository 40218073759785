import React, { useState } from "react";
import "../style/map-key.scss";
import "../style/map-key-legend.scss";
import "../style/stickyBar.scss";
import SwitchToggle from './SwitchToggle';
import "../style/map-key-mbl.scss";

const MapKey = (props) => {

    const [collapsed, toggleCollapse] = useState(true);

    return (
        <div className="col-md-12 map-legend-mbl is-sticky">
            <div className="map-key-mbl">
                <div className="map-key-header">
                    <div className="toggle" onClick={ ()=>toggleCollapse(!collapsed) }>{collapsed ? 'SHOW KEY' : 'SHOW KEY'}<span className={collapsed ? 'up-arrows' : 'dwn-arrows'}></span></div>
                </div>
                <div className={collapsed ? 'map-key-body hidden':'map-key-body'}>
                    <ul className="key-list">
                        {props.isCases ? 
                        <li className="key-mbl"><span className="mark mortality-rate"></span>MORTALITY RATE PER 100,000 AT RISK</li>:
                        <li className="key-mbl"><span className="mark incidence-rate"></span>INCIDENCE RATE PER 1,000 AT RISK</li>}
                        {props.isCases ? 
                        <li><span className="mark target-incidence"></span><span className="mark target-incidence"></span>TARGET MORTALITY RATE PER 100,000 AT RISK</li>:
                        <li><span className="mark target-incidence"></span><span className="mark target-incidence"></span>TARGET INCIDENCE RATE PER 1,000 AT RISK</li>
                        }
                    </ul>
                </div>
                <SwitchToggle isOn={props && props.isCases} onColor="#FF5AE4" handleToggle={props && props.handleToggle} />
            </div>
        </div>
    );
}

export default MapKey;



