import React, { Component } from "react";
import * as d3 from "d3";

class TextTransition extends Component {
    componentDidUpdate () {
        var format = d3.format(",d");
        const { currentCuntry, append } = this.props;
        d3.select("#id-" + append)
            .transition()
            .duration(4000)
            .on("start", function repeat () {
                d3.active(this)
                    .tween("text", function () {
                        var that = d3.select(this),
                            i = d3.interpolateNumber(that.text().replace(/,/g, ""), currentCuntry);
                        return function (t) { that.text(format(i(t))); };
                    });
                // .transition()
                //   .delay(1500);
                // .on("start", repeat);
            });
    }

    render () {
        const { append } = this.props;
        return (
            <>

                <div id={ 'id-' + append } className="country-detail-no">0</div>
            </>
        );
    }
}

export default TextTransition;
