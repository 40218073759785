/**
 * CSV data files URLs
 */
export const COUNTRIES_COLOR_URL = `${process.env.PUBLIC_URL}/data/map_countries_colour.csv`;//'./data/map_countries_colour.csv';
export const COUNTRIES_PROGRESS_URL = `${process.env.PUBLIC_URL}/data/data_tracker_progress.csv`;//'./data/tracker_data_progress.csv';
export const COUNTRIES_PROJECTION_URL = `${process.env.PUBLIC_URL}/data/data_tracker_projection.csv`;//'./data/tracker_data_projection.csv';
export const MAP_JSON_URL = `${process.env.PUBLIC_URL}/data/worldData.json`;//'./data/worldData.json';

/**
 * COLORS: Object containing all colors used
 */
export const LEGEND_COLORS = {
    ON_TRACK: {
        text: 'On track',
        color: '#78CF5B'
    },
    OFF_TRACK: {
        text: 'Off track',
        color: '#D43225'
    }
}


export const REGION_LIST = {
    SHOW_ALL: 'SHOW ALL',
    AFRO: 'AFRO',
    PAHO: 'PAHO',
    SEARO: 'SEARO',
    WPRO: 'WPRO',
    EMRO: 'EMRO'
}

export const CHART_TABS = {
    DEATHS: 'DEATHS',
    CASES: 'CASES'
}

export const VIEWS = {
    MAP: 'MAP',
    DETAIL: 'DETAIL'
}

export const DATA_YEARS = [
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030"
]

export const NEAR_TRACK_MARGIN = 10
export const START_YEAR = 2015
export const END_YEAR = 2030
export const CURRENT_YEAR = 2022;

export const ui_breakpoint = 768;

