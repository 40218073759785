import React, { Component } from "react";
import "../style/toggle-popUp.scss";

class TogglePopUp extends Component {
  constructor() {
    super();
    this.state = {
      showModal: true,
    };
  }

  closeModal() {
    //this.props.action;
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div
        className={`${
          this.state.showModal ? "toggle-popup" : "toggle-popup-hide"
        } mask-bottom-toggle`}
      >
        <div className="close-popup" onClick={() => this.closeModal()}></div>
        <div className="popup-header"></div>
        <div className="popup-body">
          <p>USE THE TOGGLE TO CHANGE BETWEEN CASES AND DEATHS</p>
          <div className="arrow-img"></div>
        </div>
      </div>
    );
  }
}

export default TogglePopUp;
