import React, { useState, useEffect } from 'react';
import {selectAll} from 'd3';
import '../style/line-chart.scss';
import lineChart from "../utils/linechartutil";

const NewLineChart = (props) => {
  
  let width = props.isGrid ? 420 : 1220
  if(window.innerWidth <= 768) {
    width = 420;
  }
  const [chartwidth, setWidth] = useState(width);

  let height = props.isGrid ? 249 : 417
  if(window.innerWidth <= 768) {
    height = 249;
  }

  const[chartHeight, setHeight] = useState(height);

  useEffect(() => {
    window.addEventListener('resize', resize);
    
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    renderLineChart();
  }, [props]);

  function resize() {
    const width = getDeviceWidth(window.innerWidth);
    setWidth(width);
  }

  function getDeviceWidth(width) {
    switch (width) {
      case 1024:
        return '420';
      case 768:
        return '340';
      default:
        return width > 500 ? '1080' : width - 20;
    }
  }

  const renderLineChart = () => {
    const width = chartwidth;
    const height = chartHeight;
    const { country } = props;
    const svg = selectAll('.line-chart svg.' + country.replaceAll(' ','-'));
    const margin = {
      top: '20',
      bottom: '20',
      left: '75',
      right: '75',
    };
    svg.attr('width', chartwidth).attr('height', chartHeight);
    lineChart(svg, { margin, width, height }, props);
  };

  return (
    <div className="line-chart">
      <svg className={props.country.replaceAll(' ','-')} />
    </div>
  );
};

export default NewLineChart;
